import { zodResolver } from "@hookform/resolvers/zod";
import { GoogleLogin } from "@react-oauth/google";
import React from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { z } from "zod";

import { HandraiseLogoMark } from "@/assets/handraise";
import BackgroundGradientLayout from "@/components/common/Gradient";
import { Button } from "@/components/ui/controls/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/forms/form";
import { Input } from "@/components/ui/forms/input";
import { useAppDispatch } from "@/lib/hooks/storeHooks";
import { startGoogleLogin, startLogin } from "@/store/actions/auth";
import { selectAuthStatus } from "@/store/selectors/authSelector";
import { CaretLeft, Eye, EyeSlash } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

const formSchema = z.object({
  email: z
    .string()
    .email("Please enter a valid email address.")
    .min(1, { message: "Email address is required." }),
  password: z
    .string({
      required_error: "Password is required",
    })
    .min(6, { message: "Password should be at least 6 characters long." })
    .max(50, { message: "Password should not exceed 50 characters." }),
});

const Login = () => {
  const dispatch: any = useAppDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordInput, setShowPasswordInput] = React.useState(false);
  const authStatus = useSelector(selectAuthStatus);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    watch,
    formState: { errors },
  } = form;
  const [email, password] = watch(["email", "password"]);

  const responseGoogle = (response: any) => {
    const { clientId, credential } = response;
    dispatch(
      startGoogleLogin({ client_id: clientId, access_token: credential }),
    );
  };

  const handleSubmit = () => {
    if (!showPasswordInput) {
      setShowPasswordInput(true);
    } else {
      dispatch(startLogin({ email, password }));
    }
  };

  const onContinue = () => {
    if (!errors.email) {
      setShowPasswordInput(true);
    }
    return false;
  };

  return (
    <BackgroundGradientLayout>
      <div className="flex justify-center items-center h-screen login-wrapper">
        {showPasswordInput && (
          <div className="absolute top-5 left-5">
            <Button
              type="button"
              variant={"ghost"}
              className="btn bg-white back-btn flex items-center justify-center"
              onClick={() => setShowPasswordInput(false)}
            >
              <CaretLeft className="me-2" />
              BACK
            </Button>
          </div>
        )}

        <div className="bg-white px-8 pt-6 pb-8 mb-28">
          <div className="w-full flex items-center justify-center">
            <HandraiseLogoMark className="w-48" />
          </div>
          <h1 className="text-center pt-6 font-serif text-xl">Welcome back.</h1>
          <h2 className="text-center pt-2 pb-6 text-slate-600">
            Supercharge your news with AI.
          </h2>
          {!showPasswordInput && (
            <>
              <div className="mb-6 w-full flex items-center justify-center">
                <GoogleLogin onSuccess={responseGoogle} theme="outline" />
              </div>

              <div className="mb-6 flex items-center justify-center">
                <div className="w-full h-px bg-slate-200" />
                <div className="text-sm text-slate-500 mx-2.5">or</div>
                <div className="w-full h-px bg-slate-200" />
              </div>
            </>
          )}
          <Form {...form}>
            {!showPasswordInput && (
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        placeholder="Enter email address"
                        className="rounded-full"
                        {...field}
                        onKeyUp={(
                          event: React.KeyboardEvent<HTMLInputElement>,
                        ) => {
                          if (event.key === "Enter" && !showPasswordInput) {
                            onContinue();
                            event.preventDefault();
                          }
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {showPasswordInput && (
              <>
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Password</FormLabel>
                      <br />
                      <FormControl>
                        <div className="inline-flex">
                          <Input
                            placeholder="Enter your password"
                            {...field}
                            type={showPassword ? "text" : "password"}
                            className={
                              "rounded-full rounded-r-none w-56 text-slate-800"
                            }
                            onKeyUp={(
                              event: React.KeyboardEvent<HTMLInputElement>,
                            ) => {
                              if (event.key === "Enter") {
                                handleSubmit();
                                event.preventDefault();
                              }
                            }}
                          />
                          <Button
                            type="button"
                            variant="outline"
                            size="inline"
                            className="z-10 rounded-full rounded-l-none"
                            aria-label={
                              showPassword ? "Hide password" : "Show password"
                            }
                            onClick={() =>
                              setShowPassword((showPassword) => !showPassword)
                            }
                          >
                            {showPassword ? (
                              <EyeSlash className="h-5 w-5" />
                            ) : (
                              <Eye className="h-5 w-5" />
                            )}
                          </Button>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Link
                  to="/auth/forgot-password"
                  className="text-orange-400 text-xs block mb-6 ms-2"
                >
                  Forgot password?
                </Link>
              </>
            )}
          </Form>
          <div className="flex items-center justify-center relative z-10">
            {!showPasswordInput ? (
              <Button
                className="rounded-full w-full text-slate-900"
                variant="outline"
                type="button"
                onClick={onContinue}
              >
                Continue
              </Button>
            ) : (
              <Button
                className="rounded-full w-full"
                variant="outline"
                onClick={handleSubmit}
                state={authStatus === "loading" ? "loading" : "default"}
              >
                Sign in
              </Button>
            )}
          </div>
          <div className="items-center justify-center mt-6 hidden">
            <p className="text-slate-400 text-xs">
              Don't have an account?{" "}
              <Link to="/auth/register" className="text-blue-400">
                Sign up
              </Link>
            </p>
          </div>
        </div>

        {showPasswordInput && (
          <div className="fixed bottom-10">
            <p className="text-xs text-slate-600">
              By signing in, you agree to our{" "}
              <a
                href="#"
                target="_blank"
                className="text-orange-400"
                rel="noreferrer"
              >
                privacy policy
              </a>
            </p>
          </div>
        )}
      </div>
    </BackgroundGradientLayout>
  );
};

export default React.memo(Login);
