import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

import { Button } from "@/components/ui/controls/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/forms/form";
import { Input } from "@/components/ui/forms/input";

import { HandraiseLogoMark } from "@/assets/handraise";
import { resetPasswordConfirm } from "@/store/actions/auth";

const formSchema = z
  .object({
    password: z
      .string()
      .min(6, { message: "Password should be at least 6 characters long." })
      .max(50, { message: "Password should not exceed 50 characters." }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

const ForgotPasswordConfirm = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const {
    watch,
    formState: { errors },
  } = form;
  const [password, confirmPassword] = watch(["password", "confirmPassword"]);

  const handleSubmit = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const uid = queryParams.get("u");
    const token = queryParams.get("k");

    dispatch(
      resetPasswordConfirm({
        uid,
        token,
        new_password1: password,
        new_password2: confirmPassword,
      }),
    );
    navigate("/login");
  };

  return (
    <div className="flex justify-center items-center h-screen login-wrapper">
      <Form {...form}>
        <div className="bg-white px-8 pt-6 pb-8 mb-28">
          <div className="w-full flex items-center justify-center">
            <HandraiseLogoMark className="w-48" />
          </div>
          <h1 className="text-center font-bold mb-6">Set New Password</h1>
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="New Password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    type="password"
                    placeholder="Confirm New Password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex items-center justify-center">
            <Button
              type="button"
              onClick={handleSubmit}
              disabled={!!errors.password || !!errors.confirmPassword}
            >
              Reset Password
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default React.memo(ForgotPasswordConfirm);
