import * as Sentry from "@sentry/react";
import { apiEndpointBaseUrl } from "./constants";
import { getAuthToken } from "./tokenManagement";
import type { FetchOptions } from "./types";

const createHeaders = async (
  method: string,
  token?: string,
  tenant?: string,
  appId?: string,
): Promise<Headers> => {
  const headers = new Headers();

  if (method !== "GET") {
    headers.append("Content-Type", "application/json");
  }

  const authToken = await getAuthToken(token);

  if (authToken) headers.append("Authorization", `Bearer ${authToken}`);
  if (tenant) headers.append("X-NA-TENANT", tenant);
  if (appId) headers.append("X-NAAPI-APPLICATION", appId);

  return headers;
};

export const standardAuthFetch = async (
  input: RequestInfo | URL,
  init?: RequestInit,
) => {
  const token = localStorage.getItem("access_token") || "";
  const method =
    init?.method || (input instanceof Request ? input.method : "GET") || "GET";
  const headers = await createHeaders(method, token);

  let request: Request;
  if (input instanceof Request) {
    const combinedHeaders = new Headers(input.headers);
    headers.forEach((value, key) => {
      combinedHeaders.set(key, value);
    });
    request = new Request(input, {
      ...init,
      headers: combinedHeaders,
    });
  } else {
    request = new Request(input, {
      ...init,
      headers,
    });
  }

  return fetch(request);
};

const authenticatedFetch = async (
  endpoint: string,
  {
    data = {},
    method = "GET",
    token = localStorage.getItem("access_token") || "",
    tenant,
    appId,
  }: FetchOptions = {},
) => {
  try {
    const url = `${apiEndpointBaseUrl}/${endpoint}`;
    const headers = await createHeaders(method, token, tenant, appId);

    return fetch(url, {
      method,
      headers,
      body: method !== "GET" ? JSON.stringify(data) : undefined,
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export default authenticatedFetch;
