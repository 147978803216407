import { useEffect, useState } from "react";

import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/components/ui/feedback/alert";
import { Dialog, DialogContent } from "@/components/ui/feedback/dialog";
import useAdblockDetector from "@/lib/hooks/useAdblockDetector";
import { WarningOctagon } from "@phosphor-icons/react";

export default function CustomAdsBlockerDialog() {
  const isAdblockEnabled = useAdblockDetector();
  const [showModal, setShowModal] = useState(false);
  const pathname = window.location.pathname;

  useEffect(() => {
    const adblockerDiscarded = localStorage.getItem("adblocker-discarded");
    if (
      isAdblockEnabled &&
      adblockerDiscarded !== "true" &&
      !pathname.includes("preview")
    ) {
      setShowModal(true);
    }
  }, [isAdblockEnabled, pathname]);

  const onDiscard = (isVisible: boolean) => {
    if (isVisible === false) {
      localStorage.setItem("adblocker-discarded", "true");
    }
    setShowModal(isVisible);
  };

  return (
    <Dialog open={showModal} onOpenChange={onDiscard}>
      <DialogContent>
        <Alert className="border-none">
          <WarningOctagon />
          <AlertTitle>Your ad blocker may affect some features.</AlertTitle>
          <AlertDescription>
            To fully experience everything we offer, please consider disabling
            your ad blocker for our app.
          </AlertDescription>
        </Alert>
      </DialogContent>
    </Dialog>
  );
}
