interface HandraiseLogoMarkProps {
  className?: string;
}

export const HandraiseLogoMark: React.FC<HandraiseLogoMarkProps> = ({
  className,
}) => (
  <svg
    width="374"
    height="50"
    viewBox="0 0 374 50"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    className={`fill-slate-900 ${className}`}
    aria-label="Handraise"
  >
    <path d="M360.121 60.5985C365.55 60.5985 368.807 58.7039 370.979 55.4089L373.568 56.7269C371.981 62.7403 366.719 69.4127 356.696 69.4127C344.919 69.4127 337.568 60.7633 337.568 48.8188C337.568 35.8859 344.585 26.4127 356.696 26.4127C367.053 26.4127 373.568 33.2499 373.568 44.9472H346.088C346.756 55.0794 352.018 60.5985 360.121 60.5985ZM354.942 30.202C349.513 30.202 346.339 34.156 346.005 41.3227H362.376C362.376 34.7326 360.455 30.202 354.942 30.202Z" />
    <path d="M317.682 69.4127C313.146 69.4127 307.769 68.3439 303.568 65.3018V54.9424H306.929C308.441 61.6842 313.398 65.1374 319.11 65.1374C323.395 65.1374 326.671 63.2464 326.671 59.4643C326.671 56.6689 325.159 55.0246 321.715 53.7913L313.566 50.8315C307.853 48.9404 303.652 45.1584 303.652 39.2387C303.652 31.2636 310.457 26.4127 319.95 26.4127C324.319 26.4127 329.612 27.646 332.804 29.2081V38.581H329.444C328.016 33.5657 323.899 30.277 318.522 30.277C314.322 30.277 311.717 32.3324 311.717 35.2923C311.717 38.0055 312.978 39.8143 316.506 41.0475L325.915 44.5829C331.376 46.5561 334.568 50.0915 334.568 55.8468C334.568 64.233 327.847 69.4127 317.682 69.4127Z" />
    <path d="M289.946 21.3115C286.262 21.3115 283.561 18.4821 283.561 14.8205C283.561 11.2421 286.262 8.41272 289.946 8.41272C293.629 8.41272 296.33 11.2421 296.33 14.8205C296.33 18.4821 293.629 21.3115 289.946 21.3115ZM301.568 65.5001V68.4127H278.978V65.5001C284.052 65.1672 285.362 64.1686 285.362 59.8413V37.7054C285.362 34.7095 284.216 33.5445 281.597 33.5445C280.778 33.5445 279.796 33.6277 278.568 33.7941V30.8815L291.91 26.9703H295.102V59.6749C295.102 64.2518 296.739 65.1672 301.568 65.5001Z" />
    <path d="M276.659 61.0104L278.568 62.3284C276.991 67.1062 273.34 69.3303 269.19 69.3303C264.791 69.3303 261.72 66.9415 261.139 61.8342H260.558C259.313 66.2001 255.081 69.4127 249.188 69.4127C242.465 69.4127 237.568 65.8706 237.568 58.7863C237.568 52.1139 242.631 47.8303 252.84 45.8533L260.724 44.2882V42.0641C260.724 36.3802 257.902 33.1675 251.927 33.1675C246.615 33.1675 243.212 35.5564 241.386 39.6752L238.647 38.6043C240.141 32.179 245.287 26.4127 255.33 26.4127C265.123 26.4127 270.601 31.8495 270.601 42.5583V58.951C270.601 61.4223 271.597 62.9051 273.589 62.9051C275 62.9051 275.995 62.1637 276.659 61.0104ZM254.168 63.7288C257.238 63.7288 259.479 61.9989 260.724 58.5392V47.5008L256.906 48.2422C251.346 49.3955 247.943 52.0315 247.943 56.974C247.943 61.0928 250.267 63.7288 254.168 63.7288Z" />
    <path d="M234.12 26.4127C235.049 26.4127 235.893 26.4951 236.568 26.7421V37.448H234.711C233.782 36.8715 232.431 36.5421 230.743 36.5421C226.183 36.5421 221.708 38.601 221.708 45.9304V59.7657C221.708 64.5421 223.903 65.201 230.405 65.5304V68.4127H204.991V65.5304C210.225 65.1186 211.576 64.2127 211.576 59.9304V37.6127C211.576 35.1421 210.479 34.0715 207.692 34.0715C206.932 34.0715 205.666 34.1539 204.568 34.2362V31.4362L217.487 27.401H220.695V34.8127C220.695 37.8598 220.526 41.648 220.273 43.6245L220.864 43.7068L221.793 38.4363C223.059 30.448 228.125 26.4127 234.12 26.4127Z" />
    <path d="M204.568 65.5163V68.4179H189.18V65.4334C189.18 63.6096 189.43 61.1226 190.345 58.5526L189.846 58.3039C187.6 65.1847 183.108 69.4127 175.955 69.4127C166.14 69.4127 159.568 61.4542 159.568 49.9309C159.568 35.0915 169.55 26.1381 181.777 26.1381C184.522 26.1381 186.768 26.7184 188.515 27.6303V16.0241C188.515 12.9568 187.184 11.7132 184.273 11.7132C183.108 11.7132 181.611 11.9619 179.864 12.2106V9.5578L195.502 5.41272H198.413V60.4594C198.413 64.107 200.077 65.1847 204.568 65.5163ZM188.515 57.6407V32.2728C187.101 30.6977 184.938 29.8687 182.027 29.8687C175.04 29.8687 170.049 35.506 170.049 46.449C170.049 55.8998 174.042 61.9516 180.613 61.9516C184.356 61.9516 187.018 60.1277 188.515 57.6407Z" />
    <path d="M158.568 65.5304V68.4127H137.044V65.5304C140.813 65.201 141.985 63.8833 141.985 60.5892V41.9774C141.985 36.048 139.222 33.1657 134.28 33.1657C130.26 33.1657 127.161 34.8951 125.486 38.5186V59.9304C125.486 64.2951 126.575 65.201 130.511 65.5304V68.4127H108.903V65.5304C114.096 65.201 115.52 64.2127 115.52 59.9304V36.9539C115.52 34.9774 113.928 33.9892 111.835 33.9892C111.081 33.9892 109.992 34.0715 108.568 34.2362V31.4362L121.382 27.401H124.565V32.0951C124.565 33.6598 124.23 35.3068 123.644 37.5304L124.23 37.6951C126.575 30.5304 131.684 26.4127 139.054 26.4127C146.592 26.4127 151.952 30.3657 151.952 40.4127V59.7657C151.952 64.2951 153.627 65.201 158.568 65.5304Z" />
    <path d="M106.659 61.0104L108.568 62.3284C106.991 67.1062 103.34 69.3303 99.1898 69.3303C94.791 69.3303 91.7202 66.9415 91.1392 61.8342H90.5582C89.3133 66.2001 85.0805 69.4127 79.1878 69.4127C72.4651 69.4127 67.5684 65.8706 67.5684 58.7863C67.5684 52.1139 72.6311 47.8303 82.8396 45.8533L90.7242 44.2882V42.0641C90.7242 36.3802 87.9024 33.1675 81.9267 33.1675C76.6149 33.1675 73.2121 35.5564 71.3862 39.6752L68.6473 38.6043C70.1412 32.179 75.287 26.4127 85.3295 26.4127C95.123 26.4127 100.601 31.8495 100.601 42.5583V58.951C100.601 61.4223 101.597 62.9051 103.589 62.9051C105 62.9051 105.995 62.1637 106.659 61.0104ZM84.1676 63.7288C87.2384 63.7288 89.4793 61.9989 90.7242 58.5392V47.5008L86.9064 48.2422C81.3457 49.3955 77.9428 52.0315 77.9428 56.974C77.9428 61.0928 80.2667 63.7288 84.1676 63.7288Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M56.0264 56.1386V15.0277C56.0264 7.7526 57.6152 4.92266 64.0541 4.33731V1.32693L37.3788 0.70636V2.47559C43.5668 3.06095 45.1556 7.50174 45.1556 15.0277V36.2366H18.8985V24.3844C18.8985 17.0257 20.4036 15.5205 26.6753 14.9351V11.9248H0V14.9351C6.35523 15.5205 8.11129 16.9421 8.11129 24.3844V56.1386C8.11129 63.4136 6.52248 65.0024 0 65.5878V68.5982H26.6753V65.5878C20.5709 65.0024 18.8985 63.6645 18.8985 56.1386V42.4156H45.1556V56.1386C45.1556 63.4973 43.734 65.0024 37.3788 65.5878V68.5982H64.0541V65.5878C57.7825 65.0024 56.0264 63.5809 56.0264 56.1386Z"
    />
  </svg>
);
