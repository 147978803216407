import { isLocal, isProduction } from "@/config";
import {
  browserTracingIntegration,
  captureException,
  captureMessage,
  init,
  metrics,
  reactRouterV6BrowserTracingIntegration,
  replayIntegration,
} from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

export default function initializeLogging() {
  if (isLocal) return;
  const appUrl = import.meta.env.VITE_APP_URL;
  const apiUrl = import.meta.env.VITE_API_URL;
  const wsApiUrl = import.meta.env.VITE_WEBSOCKET_URL;
  const dsn =
    "https://9885120d38efdc6218ce45aad9f01bf2@o1291819.ingest.sentry.io/4506488575688704";

  init({
    dsn,
    debug: !isProduction,
    //TODO add release with release and source maps
    environment: import.meta.env.VITE_ENVIRONMENT,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      metrics.metricsAggregatorIntegration(),
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      browserTracingIntegration(),
    ],
    tracePropagationTargets: [
      appUrl,
      new RegExp(`^${apiUrl}/api`),
      new RegExp(`^${apiUrl}/graphql/`),
      new RegExp(`^${wsApiUrl}/graphql/`),
    ],
    profilesSampleRate: 1,
    tracesSampleRate: 1,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
  });

  const originalConsole = console;
  console = {
    ...console,
    log: (...args) => {
      originalConsole.log(...args);
      captureMessage(args.join(" "), {
        level: "log",
        extra: {
          message: args.join(" "),
        },
      });
    },
    warn: (...args) => {
      originalConsole.warn(...args);
      captureMessage(args.join(" "), {
        level: "warning",
        extra: {
          message: args.join(" "),
        },
      });
    },
    error: (...args) => {
      originalConsole.error(...args);
      captureException(new Error(args.join(" ")), {
        level: "error",
        extra: {
          message: args.join(" "),
        },
      });
    },
    info: (...args) => {
      originalConsole.info(...args);
      captureMessage(args.join(" "), {
        level: "info",
        extra: {
          message: args.join(" "),
        },
      });
    },
  };
}
