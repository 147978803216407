import { isProduction } from "@/config";
import { accountReducer } from "@/store/reducers/accountReducer";
import authReducer from "@/store/reducers/authReducer";
import { builderReducer } from "@/store/reducers/builderReducer";
import type { ConfigureStoreOptions, Middleware } from "@reduxjs/toolkit";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { emptySplitApi } from "./emptyApi";
import { campaignApi } from "./generated-apis/campaignApi";

// Check for Redux DevTools extension, which is great for debugging Redux apps.
// Install it from: https://chromewebstore.google.com/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd
const hasReduxDevtoolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

const middleware: ConfigureStoreOptions["middleware"] = (
  getDefaultMiddleware,
) =>
  !isProduction && !hasReduxDevtoolsExtension
    ? getDefaultMiddleware()
        .concat(logger as Middleware)
        .concat(campaignApi.middleware)
    : getDefaultMiddleware().concat(campaignApi.middleware);

const store = configureStore({
  reducer: {
    auth: authReducer,
    account: accountReducer,
    builder: builderReducer,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer,
  },
  middleware,
  devTools: !isProduction,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
