import { useFeature } from "@/lib/feature-management";
import { useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ForgotPasswordScreen from "./ForgotPassword";
import ForgotPasswordConfirm from "./ForgotPasswordConfirm";
import LoginScreen from "./Login";
import RegisterScreen from "./Register";

const useAuthRoutes = (loggedIn: boolean) => {
  const registrationEnabled = useFeature("allow-registration", false);

  const authRoutes = (loggedIn: boolean) => ({
    path: "/auth",
    element: !loggedIn ? <Outlet /> : <Navigate to="/" />,
    children: [
      {
        path: "login",
        Component: LoginScreen,
      },
      {
        path: "forgot-password",
        Component: ForgotPasswordScreen,
      },
      {
        path: "forgot-password-confirm",
        Component: ForgotPasswordConfirm,
      },
      {
        path: "register",
        Component: () =>
          registrationEnabled ? (
            <RegisterScreen />
          ) : (
            <Navigate to="/auth/login" />
          ),
      },
      {
        path: "*",
        element: <Navigate to="/auth/login" />,
      },
    ],
  });

  return useMemo(() => authRoutes(loggedIn), [loggedIn]);
};

export default useAuthRoutes;
