import { cn } from "@/lib/utils";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { type VariantProps, cva } from "class-variance-authority";
import React from "react";
import { type ReactNode, useState } from "react";

const inputVariants = cva(
  cn(
    "flex px-3 py-2 self-stretch leading-tight bg-white border border-slate-300 justify-start items-start",
    "text-black text-sm font-normal leading-normal",
    "focus-visible:outline-none focus-visible:ring-1",
    "disabled:cursor-not-allowed disabled:text-slate-400 disabled:bg-slate-100",
    "disabled:border-slate-300",
    "bg-white rounded-md border border-violet-900 justify-start items-start w-full",
  ),
  {
    variants: {
      variant: {
        default: "focus-visible:ring-orange-400",
        search:
          "focus-visible:ring-slate-400 w-full h-9 px-3 py-2 bg-white rounded-md shadow border border-slate-100 justify-start items-center gap-2 inline-flex",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof inputVariants> & {
    field?: any;
    prefix?: ReactNode;
    variant?: "default" | "search";
  };

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, field, variant, prefix, type, ...props }, ref) => {
    const prefixRef = React.createRef<HTMLSpanElement>();

    const [padding, setPadding] = useState(8);

    React.useLayoutEffect(() => {
      if (prefixRef.current) {
        setPadding(prefixRef.current.offsetWidth + 32);
      }
    }, [prefixRef]);

    return (
      <div className={cn("relative", className)}>
        {prefix && (
          <span
            ref={prefixRef}
            className="absolute text-sm left-4 pr-4 top-1/2 transform -translate-y-1/2"
          >
            {prefix}
          </span>
        )}
        {variant === "search" && (
          <div className="absolute left-2.5 text-lg top-1/2 transform -translate-y-1/2 text-slate-400">
            <MagnifyingGlass />
          </div>
        )}
        <input
          type={type}
          {...field}
          className={cn(
            inputVariants({
              variant: variant || "default",
            }),
            className,
          )}
          style={{
            paddingLeft: variant === "search" ? 32 : padding, // adjust padding for search variant
            borderColor: "#D1C6DE",
          }}
          ref={ref}
          {...props}
        />
      </div>
    );
  },
);
Input.displayName = "Input";
export { Input };
