import { FeaturesReady } from "@growthbook/growthbook-react";
import { type ReactNode, Suspense, createElement, lazy } from "react";
import { useDispatch } from "react-redux";
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { useAppSelector } from "@/lib/hooks/storeHooks";
import { CustomLoader } from "@/shared/CustomLoader";
import { selectCampaign } from "@/store/actions/builder";

import CustomAdsBlockerDialog from "./components/common/CustomAdsBlockerDialog";

import SettingsLayout from "./components/layouts/SettingsLayout";
import useAuthRoutes from "./pages/auth/useAuthRoutes";
import { LazyLoader } from "./shared/LazyLoader";

const MainLayout = lazy(() => import("./components/layouts/MainLayout"));
const CampaignEdit = lazy(() => import("./pages/campaigns/editCampaign"));
const StoryPage = lazy(() => import("./pages/stories/StoryPage"));
const SettingsPage = lazy(() => import("./modules/settings/SettingsScreen"));
const NewsFeedsPage = lazy(() => import("./pages/NewsFeedsPage"));
const NewsFeedsScreen = lazy(() => import("./pages/newsfeeds/FeedPage"));
const CampaignsPage = lazy(() => import("./modules/builder/CampaignsScreen"));
const ArticlePreview = lazy(
  () => import("./modules/article-preview/ArticlePreview"),
);

export default function AppRouter() {
  const { checking, loggedIn } = useAppSelector((state) => state.auth);
  // biome-ignore lint/suspicious/noExplicitAny: <using outdated dispatch that is not setup correctly, any is required>
  const dispatch: any = useDispatch();
  const authRoutes = useAuthRoutes(loggedIn);

  const getProtectedElement = (
    element: React.FC<{ children: ReactNode[] | ReactNode }>,
    redirectTo = "/auth/login",
  ) => {
    return loggedIn ? (
      // biome-ignore lint/correctness/noChildrenProp: <react router dom requires this>
      createElement(element, { children: <Outlet /> })
    ) : (
      <Navigate to={redirectTo} />
    );
  };

  if (checking) {
    return <CustomLoader />;
  }

  const router = createBrowserRouter([
    authRoutes,
    {
      path: "/article-preview/:id",
      Component: ArticlePreview,
    },
    {
      path: "/",
      element: getProtectedElement(MainLayout),
      children: [
        {
          path: "",
          element: <Navigate to="/newsfeeds" />,
        },
        {
          path: "newsfeeds",
          children: [
            {
              path: "",
              element: <NewsFeedsPage />,
            },
            {
              path: ":feedId/*",
              children: [
                {
                  path: "story/:storyId/*",
                  children: [
                    {
                      path: "*",
                      element: <StoryPage />,
                    },
                  ],
                },
                {
                  path: "*",
                  element: <NewsFeedsScreen />,
                },
              ],
            },
          ],
        },
        {
          path: "campaigns",
          children: [
            {
              path: "",
              Component: CampaignsPage,
            },
            {
              path: ":campaignId",
              loader: async ({ params: { campaignId } }) => {
                if (!campaignId) {
                  throw new Response("No campaign id provided", {
                    status: 400,
                  });
                }
                return await dispatch(
                  selectCampaign(campaignId ? +campaignId : 0),
                );
              },
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <Navigate to="edit" replace />,
                },
                {
                  path: "edit",
                  Component: CampaignEdit,
                },
              ],
            },
          ],
        },
        {
          path: "*",
          element: <Navigate to="/newsfeeds" />,
        },
      ],
    },
    {
      path: "/settings",
      element: getProtectedElement(SettingsLayout),
      children: [
        {
          path: "",
          Component: SettingsPage,
        },
      ],
    },
  ]);

  return (
    <Suspense fallback={<LazyLoader delay={300} />}>
      <FeaturesReady timeout={500} fallback={<CustomLoader />}>
        <CustomAdsBlockerDialog />
        <RouterProvider router={router} />
      </FeaturesReady>
    </Suspense>
  );
}
