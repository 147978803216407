import { toast } from "react-toastify";

import authenticatedFetch from "@/data-access/core/authenticatedFetch";
import { authLogin } from "@/store/reducers/authReducer";
import type { AppDispatch, RootState } from "@/store/store";
import type { UserInfo } from "@/types/user/userInfo";
import { toastOptions } from "../../config";
import { types } from "../../types/types";

interface AccountState {
  email?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  avatar?: string;
}

export const accountReset = () => ({
  type: types.accountReset,
});

const updateAccount = (user: UserInfo) => ({
  type: types.accountUpdate,
  payload: user,
});

const changePassword = () => ({
  type: types.accountChangePassword,
});

export const startUpdateAccount = (user: AccountState, showToast = true) => {
  return async (dispatch: AppDispatch) => {
    try {
      const resp = await authenticatedFetch("auth/user/", {
        data: user,
        method: "PATCH",
      });
      const body = await resp.json();

      if (body.email) {
        localStorage.setItem("newsamp_user", JSON.stringify(body));
        dispatch(updateAccount(body));
        dispatch(authLogin(body));
        if (showToast) {
          toast.success("Account updated successfully", toastOptions);
        }
      } else {
        if (showToast) {
          toast.error(body.non_field_errors[0], toastOptions);
        }
      }
    } catch (error) {
      if (showToast) {
        toast.error("An error occured, please try again.", toastOptions);
      }
    }
  };
};

export const setTmpPicture = (picture: string) => ({
  type: types.accountSetTmpPicture,
  payload: picture,
});

export const startChangePassword = (
  new_password1: string | undefined,
  new_password2: string | undefined,
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const resp = await authenticatedFetch("auth/password/change/", {
        data: { new_password1, new_password2 },
        method: "POST",
      });
      const body = await resp.json();

      if (body.detail) {
        dispatch(changePassword());
        toast.success(body.detail, toastOptions);
      } else {
        toast.error(body.non_field_errors[0], toastOptions);
      }
    } catch (error) {
      toast.error("An error occured, please try again.", toastOptions);
    }
  };
};

export const setTmpLogo = (logo: string) => ({
  type: types.accountUploadCompanyLogo,
  payload: logo,
});

export const uploadCompanyLogo = (logo: string) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const user = getState().auth.user;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      const resp = await authenticatedFetch(`tenants/${tenant}/`, {
        data: { logo },
        method: "PATCH",
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(setTmpLogo(body.logo));
        toast.success("Logo updated successfully", toastOptions);
      } else {
        toast.error(body.non_field_errors[0], toastOptions);
      }
    } catch (error) {
      toast.error("An error occured, please try again.", toastOptions);
    }
  };
};

const updateCompany = (company: { name: string; logo?: string }) => ({
  type: types.accountUpdateCompany,
  payload: company,
});

export const startUpdateCompany = (company: {
  name: string;
  logo?: string;
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const user = getState().auth.user;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      const resp = await authenticatedFetch(`tenants/${tenant}/`, {
        data: company,
        method: "PATCH",
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(updateCompany(body));
        toast.success("Company updated successfully", toastOptions);
      } else {
        toast.error(body.non_field_errors[0], toastOptions);
      }
    } catch (error) {
      toast.error("An error occured, please try again.", toastOptions);
    }
  };
};

const loadCompany = (company: { name: string; logo?: string }) => ({
  type: types.accountLoadCompany,
  payload: company,
});

export const startLoadCompany = () => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const user = getState().auth.user;
    if (user?.tenants?.length === 0) return;
    const tenant = user?.tenants?.[0]?.slug;
    try {
      const resp = await authenticatedFetch(`tenants/${tenant}/`, {
        tenant: user?.tenants?.[0]?.slug,
      });
      const body = await resp.json();

      if (resp.ok) {
        dispatch(loadCompany(body));
      } else {
        toast.error(body.non_field_errors[0], toastOptions);
      }
    } catch (error) {
      toast.error("An error occured, please try again.", toastOptions);
    }
  };
};
