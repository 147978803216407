const parseJwt = (token: string) => {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url
      ? base64Url.replace(/-/g, "+").replace(/_/g, "/")
      : "";
    return JSON.parse(atob(base64));
  } catch (e) {
    return null;
  }
};

export function isTokenExpired(token?: string): boolean {
  const tokenToParse = token || localStorage.getItem("access_token");

  if (!tokenToParse) return true;

  const parsedToken = parseJwt(tokenToParse);
  const currentTime = Math.floor(Date.now() / 1000);

  if (!parsedToken.exp) {
    throw new Error("Token does not contain required expiration claim");
  }

  return parsedToken.exp < currentTime;
}
