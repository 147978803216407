import { useUserData } from "@/store/selectors/authSelector";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

export const useUserScope = () => {
  const { loggedIn, id, email, company, employee } = useUserData();

  useEffect(() => {
    Sentry.setUser({
      id,
      email,
      company,
      loggedIn,
      employee,
    });

    datadogRum.setUser({
      id,
      email,
      company,
      loggedIn,
      employee,
    });
  }, [loggedIn, id, email, company, employee]);
};
