import {
  resetPasswordConfirm,
  startGoogleLogin,
  startLogin,
  startRegister,
  startResetPassword,
} from "@/store/actions/auth";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type UserInfo, defaultUserInfo } from "../../types/user/userInfo";

export interface AuthState {
  user: UserInfo;
  status: "idle" | "loading" | "succeeded" | "failed";
  checking?: boolean;
  loggedIn: boolean;
  error: string | null;
}

const initialState: AuthState = {
  user: JSON.parse(
    localStorage.getItem("newsamp_user") || JSON.stringify(defaultUserInfo),
  ),
  status: "idle",
  loggedIn: Boolean(localStorage.getItem("access_token")),
  checking: false,
  error: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authLogin: (state, action: PayloadAction<UserInfo>) => {
      state.user = action.payload;
      state.loggedIn = true;
      state.checking = false;
    },
    authLogout: (state) => {
      state.user = defaultUserInfo;
      state.loggedIn = false;
      state.checking = false;
    },
    authCheckingFinish: (state) => {
      state.checking = false;
    },
    authReset: (state) => {
      state.user = defaultUserInfo;
      state.loggedIn = false;
      state.checking = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(startLogin.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.loggedIn = true;
      })
      .addCase(startLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(startRegister.pending, (state) => {
        state.status = "loading";
      })
      .addCase(startRegister.fulfilled, (state, action: PayloadAction<any>) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.loggedIn = true;
      })
      .addCase(startRegister.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(startGoogleLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        startGoogleLogin.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.status = "succeeded";
          state.user = action.payload;
          state.loggedIn = true;
        },
      )
      .addCase(startGoogleLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(startResetPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(startResetPassword.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(startResetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      })
      .addCase(resetPasswordConfirm.pending, (state) => {
        state.status = "loading";
      })
      .addCase(resetPasswordConfirm.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(resetPasswordConfirm.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });
  },
});
export const { authLogin, authLogout, authCheckingFinish, authReset } =
  authSlice.actions;
export default authSlice.reducer;
