import { standardAuthFetch } from "@/data-access/core/authenticatedFetch";
import {
  dataServiceBaseUrl,
  wsServiceBaseUrl,
} from "@/data-access/core/constants";
import { getAuthToken } from "@/data-access/core/tokenManagement";
import { devtoolsExchange } from "@urql/devtools";
import { createClient as createWSClient } from "graphql-ws";
import { toast } from "sonner";
import {
  Client,
  Provider,
  cacheExchange,
  fetchExchange,
  mapExchange,
  subscriptionExchange,
} from "urql";

//https://the-guild.dev/graphql/ws/recipes#ws-server-and-client-auth-usage-with-token-expiration-validation-and-refresh
const wsClient = createWSClient({
  url: `${wsServiceBaseUrl}/graphql/`,
  connectionParams: async () => {
    const accessToken = await getAuthToken();

    if (!accessToken) return {};

    return {
      headers: {
        token: `Bearer ${accessToken}`,
      },
    };
  },
  on: {
    closed: console.error,
    connected: (socket: unknown) => {
      console.info("client connected.", socket);
    },
    error: (err) => {
      console.log(err); // 👈 does this log?
    },
  },
});

const client = new Client({
  url: `${dataServiceBaseUrl}/graphql/`,
  exchanges: [
    devtoolsExchange,
    cacheExchange,
    mapExchange({
      onError(error) {
        for (const err of error.graphQLErrors) {
          toast.error(err.message);
        }

        if (error.networkError) {
          toast.error(error.networkError.message);
        }

        console.error(error.stack);
      },
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription(request) {
        const input = { ...request, query: request.query || "" };
        return {
          subscribe(sink) {
            const unsubscribe = wsClient.subscribe(input, sink);
            return { unsubscribe };
          },
        };
      },
    }),
  ],
  fetch: standardAuthFetch,
});

const UrqlProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <Provider value={client}>{children}</Provider>;

export default UrqlProvider;
