import { toast } from "react-toastify";

export const toastOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
};

export const isProduction = import.meta.env.VITE_ENVIRONMENT === "production";
export const isLocal = import.meta.env.VITE_ENVIRONMENT === "local";
export const isDevelopment = ["local", "stage"].includes(
  import.meta.env.VITE_ENVIRONMENT,
);

export const SMALL_WINDOW_WIDTH = 640;
export const MEDIUM_WINDOW_WIDTH = 768;
