import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { AuthState } from "../reducers/authReducer";

const selectAuth = (state: { auth: AuthState }) => state.auth;

export const selectAuthStatus = createSelector(
  selectAuth,
  (auth) => auth.status,
);

export const selectLoggedIn = createSelector(
  selectAuth,
  (auth) => auth.loggedIn,
);

export const selectUser = createSelector(selectAuth, (auth) => auth.user);

export const selectUserId = createSelector(selectUser, (user) => user?.id);

export const selectUserName = createSelector(selectUser, (user) => user?.name);

export const selectUserEmail = createSelector(
  selectUser,
  (user) => user?.email,
);

export const selectTenantSlug = createSelector(
  selectUser,
  (user) => user?.default_tenant?.slug,
);

export const selectIsEmployee = createSelector(selectUserEmail, (email) =>
  email?.endsWith("@handraise.com"),
);

export const useUserData = () => {
  const loggedIn = useSelector(selectLoggedIn);
  const id = useSelector(selectUserId).toString();
  const email = useSelector(selectUserEmail);
  const company = useSelector(selectTenantSlug);
  const employee = useSelector(selectIsEmployee);
  const name = useSelector(selectUserName);

  return { loggedIn, id, email, company, employee, name };
};
