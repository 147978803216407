import { toast } from "react-toastify";

import authenticatedFetch from "@/data-access/core/authenticatedFetch";
import { apiEndpointBaseUrl } from "@/data-access/core/constants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toastOptions } from "../../config";

const setLocalStorageItems = (body: any) => {
  localStorage.setItem("access_token", body.access);
  localStorage.setItem("refresh_token", body.refresh);
  localStorage.setItem("newsamp_user", JSON.stringify(body.user));
};

export const startLogin = createAsyncThunk(
  "auth/startLogin",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      const resp = await authenticatedFetch("auth/login/", {
        data: { email, password },
        method: "POST",
      });
      const body = await resp.json();

      if (resp.ok) {
        if (body.access) {
          setLocalStorageItems(body);
          return body.user;
        }
        throw new Error(body.non_field_errors[0]);
      }
      throw new Error("Credentials are not valid");
    } catch (err) {
      const message = err instanceof Error ? err.message : String(err);
      toast.error(message, toastOptions);
      return rejectWithValue(message);
    }
  },
);

export const startRegister = createAsyncThunk(
  "auth/startRegister",
  async (
    {
      email,
      password1,
      password2,
    }: { email: string; password1: string; password2: string },
    { rejectWithValue },
  ) => {
    try {
      const resp = await fetch(`${apiEndpointBaseUrl}/auth/registration/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password1, password2 }),
      });
      const body = await resp.json();
      if (resp.ok) {
        setLocalStorageItems(body);
        return body.user;
      } else {
        const msg = body.email ? body.email[0] : body.non_field_errors[0];
        toast.error(msg, toastOptions);
        return rejectWithValue(msg);
      }
    } catch (error) {
      toast.error("Something went wrong", toastOptions);
      return rejectWithValue("Something went wrong");
    }
  },
);

export const startGoogleLogin = createAsyncThunk(
  "auth/startGoogleLogin",
  async (
    { client_id, access_token }: { client_id: string; access_token: string },
    { rejectWithValue },
  ) => {
    try {
      const resp = await fetch(`${apiEndpointBaseUrl}/auth/gauth/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ client_id, access_token }),
      });
      const body = await resp.json();
      if (resp.ok) {
        setLocalStorageItems(body);
        return body.user;
      } else {
        toast.error(body.non_field_errors[0], toastOptions);
        return rejectWithValue(body.non_field_errors[0]);
      }
    } catch (error) {
      toast.error("Something went wrong", toastOptions);
      return rejectWithValue("Something went wrong");
    }
  },
);

export const startResetPassword = createAsyncThunk(
  "auth/startResetPassword",
  async (email: string, { rejectWithValue }) => {
    try {
      const resp = await fetch(`${apiEndpointBaseUrl}/auth/password/reset/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const body = await resp.json();
      if (resp.ok) {
        toast.success(body.detail, toastOptions);
        return body.detail;
      } else {
        toast.error("An error occurred, please try again later.", toastOptions);
        return rejectWithValue("An error occurred, please try again later.");
      }
    } catch (error) {
      toast.error("An error occurred, please try again later.", toastOptions);
      return rejectWithValue("An error occurred, please try again later.");
    }
  },
);

export const resetPasswordConfirm = createAsyncThunk(
  "auth/resetPasswordConfirm",
  async (payload: any, { rejectWithValue }) => {
    try {
      const resp = await fetch(
        `${apiEndpointBaseUrl}/auth/password/reset/confirm/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
      );
      const body = await resp.json();
      if (resp.ok) {
        toast.success(body.detail, toastOptions);
        return body.detail;
      } else {
        toast.error("An error occurred, please try again later.", toastOptions);
        return rejectWithValue("An error occurred, please try again later.");
      }
    } catch (error) {
      toast.error("An error occurred, please try again later.", toastOptions);
      return rejectWithValue("An error occurred, please try again later.");
    }
  },
);
