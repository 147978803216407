import { isTokenExpired } from "@/lib/utils";
import { logoutUser } from "./auth";
import { apiEndpointBaseUrl } from "./constants";

const refreshToken = async (refreshToken: string): Promise<string | void> => {
  if (isTokenExpired(refreshToken)) {
    return await logoutUser(fetch);
  }

  try {
    const response = await fetch(`${apiEndpointBaseUrl}/auth/token/refresh/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (!response.ok) {
      throw new Error("Token refresh failed");
    }

    const body = await response.json();
    localStorage.setItem("access_token", body.access);
    localStorage.setItem("refresh_token", body.refresh);
    return body.access;
  } catch (error) {
    console.error("Error refreshing token:", error);
    await logoutUser(fetch);
    throw new Error("Error refreshing token");
  }
};

export const getAuthToken = async (
  token = localStorage.getItem("access_token") || "",
): Promise<string | void> => {
  if (token && !isTokenExpired(token)) return token;
  const refresh = localStorage.getItem("refresh_token") || "";
  if (isTokenExpired(refresh)) return await logoutUser(fetch);
  return await refreshToken(refresh);
};
