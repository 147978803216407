import { accountReset } from "@/store/actions/settings";
import { authLogout, authReset } from "@/store/reducers/authReducer";
import store from "@/store/store";
import { apiEndpointBaseUrl } from "./constants";

export const logoutUser = async (
  fetchFn: (input: RequestInfo | URL, init?: RequestInit) => Promise<Response>,
) => {
  try {
    await fetchFn(`${apiEndpointBaseUrl}/auth/logout/`, {
      method: "POST",
    });
  } catch (error) {
    console.error("Error logging out:", error);
  } finally {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("recent-searches");
    localStorage.removeItem("newsamp_user");
    localStorage.removeItem("persist:root");
    store.dispatch(authLogout());
    store.dispatch(authReset());
    store.dispatch(accountReset());
  }
};
