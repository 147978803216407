import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";
import { CircleNotch } from "@phosphor-icons/react";

const buttonVariants = cva(
  "inline-flex items-center leading-tight justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-orange-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-orange-500 text-white",
        destructive: "bg-rose-800 text-rose-50",
        outline: "bg-white shadow border border-slate-100",
        secondary:
          "bg-slate-100 text-slate-900 dark:bg-slate-900 dark:text-slate-100",
        ghost: "text-slate-800",
        link: "text-slate-900 underline-offset-4 dark:text-slate-50",
        image: "",
      },
      size: {
        default: "h-10 px-4 py-2",
        xs: "h-6 px-2 py-3.5",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-8 w-8",
        inline:
          "rounded-tl-none rounded-bl-none border-slate-300 hover:border-slate-400 p-3 ml-[-1px] h-[39px] border-l-slate-200",
        image: "h-full w-full rounded-md",
      },
      state: {
        default: "",
        loading: "bg-opacity-50 cursor-wait",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  imgSrc?: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = "default",
      size,
      children,
      asChild = false,
      state,
      imgSrc,
      leftIcon,
      rightIcon,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    const isSelected = !!imgSrc;

    const renderContent = () => {
      if (state === "loading") {
        return (
          <>
            <CircleNotch className="animate-spin h-5 w-5 mr-2" />
            {children}
          </>
        );
      }
      if (imgSrc) {
        return (
          <>
            {/* biome-ignore lint/a11y/useAltText: <existing code> */}
            <img src={imgSrc} className="cover w-full h-full rounded-md" />
            {isSelected && (
              <div className="absolute top-1 left-1">{children}</div>
            )}
          </>
        );
      }
      return children;
    };
    return (
      <Comp
        className={cn(
          buttonVariants({
            variant: imgSrc ? "image" : variant,
            size: imgSrc ? "image" : size,
            state,
          }),
          {
            "hover:bg-orange-600": state !== "loading" && variant === "default",
            "hover:bg-rose-800/90":
              state !== "loading" && variant === "destructive",
            "hover:bg-slate-500/10 active:bg-slate-700/15":
              state !== "loading" &&
              (variant === "outline" || variant === "ghost"),
            "hover:bg-slate-100/80":
              state !== "loading" && variant === "secondary",
            "hover:underline": state !== "loading" && variant === "link",
          },
          className,
          isSelected && "relative",
        )}
        ref={ref}
        {...props}
      >
        {leftIcon && <span className="mr-2">{leftIcon}</span>}
        {renderContent()}
        {rightIcon && <span className="ml-2">{rightIcon}</span>}
      </Comp>
    );
  },
);

export { Button, buttonVariants };
