import store from "@/store/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { ToastContainer } from "react-toastify";

import * as Sentry from "@sentry/react";
import { StrictMode } from "react";
import { Toaster } from "./components/ui/feedback/sonner.tsx";
import "./index.css";
import AnalyticsProvider from "./lib/analytics";
import { FeatureManagementProvider } from "./lib/feature-management";
import UrqlProvider from "./lib/urqlProvider.tsx";
import Router from "./router.tsx";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <Provider store={store}>
        <AnalyticsProvider>
          <GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_CLIENT_ID}>
            <FeatureManagementProvider>
              <UrqlProvider>
                <Router />
              </UrqlProvider>
            </FeatureManagementProvider>
          </GoogleOAuthProvider>
          <ToastContainer />
          <Toaster />
        </AnalyticsProvider>
      </Provider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
);
